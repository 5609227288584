const carousel = $('#carousel')

const firstSlide = carousel.find('.carousel-item:first[data-animation^="animate__animated"]')

const createAnimationFor = elements => {
	let animEndEv = "webkitAnimationEnd animationend";

	elements.each((_, el) => {
		let slide = $(el)

		let animationEffectClass = slide.data('animation')

		slide.addClass(animationEffectClass).one(animEndEv, () => slide.removeClass(animationEffectClass))
	})
}

carousel.carousel()

createAnimationFor(firstSlide)

carousel.on('slide.bs.carousel', ({
	relatedTarget: nextSlide
}) => createAnimationFor($(nextSlide).find('[data-animation^="animate__animated"]')))