import Alpine from 'alpinejs'

import * as Validator from 'validatorjs'

import asyncScriptLoader from 'async-script-loader'

window.Alpine = Alpine

export default class ContactForm {
    constructor() {
        this.route = document.location.pathname
        this.container = document.getElementById('form-container')
    }
    init() {
        if (this.route === '/contacto.php') {
            setTimeout(() => {
                fetch('/includes/formulario/contacto/html.php')
                    .then(response => response.ok ? response.text() : Promise.reject(response))
                    .then(html => {
                        this.container.innerHTML = (new DOMParser()).parseFromString(html, 'text/html').body.innerHTML

                        document.addEventListener('alpine:init', () => {
                            Alpine.data('formable', () => ({
                                form: document.getElementById('contact'),
                                rules: {
                                    name: 'required|string|max:255',
                                    email: 'required|string|email|max:255',
                                    message: 'required|string',
                                    'g-recaptcha-response': 'required|string'
                                },
                                request: {},
                                errors: {},
                                errorMessage: null,
                                successMessage: null,
                                validator: null,
                                pending: false,
                                timeouts: [],
                                init() {
                                    Validator.useLang('es')

                                    asyncScriptLoader('https://www.google.com/recaptcha/api.js?render=explicit').then(() => {
                                        grecaptcha.ready(() => {
                                            let submitButton = this.form.querySelector('button')

                                            grecaptcha.render('g-recaptcha', {
                                                sitekey: this.form.querySelector('[data-sitekey]').dataset.sitekey,
                                                callback: (...argscallback) => argscallback.length ? submitButton.classList.remove('d-none') : submitButton.classList.add('d-block'),
                                                'expired-callback': _ => {
                                                    grecaptcha.reset()

                                                    submitButton.classList.add('d-none')
                                                }
                                            })
                                        })
                                    }).catch(err => console.log(err))

                                    this.$watch('request', request => {
                                        this.validator = new Validator(request, this.rules)

                                        this.validator.setAttributeNames({
                                            name: 'nombre completo',
                                            email: 'correo electrónico',
                                            phone: 'teléfono',
                                            message: 'mensaje',
                                            'g-recaptcha-response': 'recaptcha'
                                        })

                                        if (this.validator.passes()) {
                                            this.pending = !this.pending

                                            fetch(this.form.action, {
                                                    method: this.form.method,
                                                    body: JSON.stringify(this.validator.input)
                                                })
                                                .then(response => response.ok ? response.json() : Promise.reject(response))
                                                .then(({
                                                    message
                                                }) => {
                                                    this.form.reset()

                                                    this.successMessage = message

                                                    this.timeouts.push(setTimeout(() => this.successMessage = null, 10000))
                                                })
                                                .catch(response => response.status >= 400 && response.json().then(({
                                                    errors
                                                }) => this.errors = errors))
                                                .finally(() => {
                                                    grecaptcha.reset()

                                                    this.pending = !this.pending

                                                    this.form.classList.remove('was-validated')

                                                    this.form.querySelector('button').classList.add('d-none')
                                                })
                                        } else {
                                            this.errors = this.validator.errors.all()
                                        }
                                    })

                                    this.$watch('errors', errors => {
                                        this.form.classList.add('was-validated')

                                        if (errors.hasOwnProperty('g-recaptcha-response')) {
                                            this.errorMessage = errors['g-recaptcha-response']

                                            this.timeouts.push(setTimeout(() => this.errorMessage = null, 10000))
                                        } else {
                                            Object.entries(errors).forEach(error => {
                                                const [key, value] = error

                                                const label = this.$refs[key]

                                                if (label) {
                                                    label.innerHTML = value
                                                }
                                            })
                                        }
                                    })
                                },
                                submitForm() {
                                    this.form.classList.remove('was-validated')

                                    this.errorMessage = this.successMessage = null

                                    this.timeouts.forEach(timeout => clearTimeout(timeout))

                                    this.request = Object.fromEntries((new FormData(this.form)).entries())
                                }
                            }))
                        })

                        Alpine.start()
                    })
                    .catch(response => console.log(response))
            }, 5000)
        }
    }
}