require('./bootstrap')

require('./interactsWithSlider')

require('./theme')

import ContactForm from './interactsWithContactForm'

import OfferForm from './interactsWithOfferForm'

(new ContactForm()).init();

(new OfferForm).init();